<template>
  <div v-if="loaded">
    <later-plans
      v-if="plan.name === 'free' || showPlans"
      buttons
      @choice="choosePlan"
    />

    <div v-if="plan.name !== 'free'">
      <div
        class="sm:my-4 sm:rounded sm:shadow sm:max-w-screen-sm mx-auto bg-white"
      >
        <h2
          class="
            p-4
            m-0
            bg-gray-dark
            shadow-inner
            text-white
            rounded-t
            font-light
          "
        >
          Subscription
        </h2>
        <div class="p-8">
          <div
            v-text="
              `You are currently on the ${capitalize(plan.name)} plan, billed ${
                plan.billed
              }.`
            "
          />
          <div class="text-right">
            <mdc-button @click.native="openPlans">
              View/Change Plan
            </mdc-button>
          </div>
          <br />
          <div>
            <div v-if="plan.ends_at">
              <div class="flex">
                <div>Your subscription will expire on</div>
                <later-date v-model="plan.ends_at" date-only class="ml-1" />.
              </div>
              <div class="text-right">
                <mdc-button @click.native="resumeSubscription">
                  Resume Subscription
                </mdc-button>
              </div>
            </div>
            <div v-else>
              <div>
                Your subscription will be renewed automatically at the end of
                this billing cycle.
              </div>
              <div class="text-right">
                <mdc-button @click.native="cancelSubscription">
                  Cancel Subscription
                </mdc-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="card || customer"
      class="sm:my-4 sm:rounded sm:shadow sm:max-w-screen-sm mx-auto bg-white"
    >
      <h2
        class="
          p-4
          m-0
          bg-gray-dark
          text-white
          shadow-inner
          rounded-t
          font-light
        "
      >
        Billing Info
      </h2>
      <div class="p-8">
        <div v-if="card">
          <p
            v-text="
              `${capitalize(card.brand)} ****${card.last4}, expires ${
                card.exp_month
              }/${card.exp_year}`
            "
          />
          <p v-text="card.name" />
          <div class="text-right">
            <mdc-button
              v-if="plan.name === 'free' || plan.ends_at"
              @click.native="deleteCard"
            >
              Delete Card
            </mdc-button>
            <mdc-button v-else @click.native="openCardInput">
              Update Card
            </mdc-button>
          </div>
        </div>
        <div v-else>
          <div v-text="'No card on file.'" />
          <div class="text-right">
            <mdc-button @click.native="openCardInput"> Add Card </mdc-button>
          </div>
        </div>
        <div v-if="false">
          <p v-text="customer.email" />
          <div class="text-right">
            <mdc-button @click.native="showEmailInput = true">
              Update Email
            </mdc-button>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="invoices && invoices.length > 0"
      class="sm:my-4 sm:rounded sm:shadow sm:max-w-screen-sm mx-auto bg-white"
    >
      <h2
        class="
          p-4
          m-0
          bg-gray-dark
          text-white
          shadow-inner
          rounded-t
          font-light
        "
      >
        Invoices
      </h2>
      <div class="p-8">
        <div
          v-for="invoice in listedInvoices"
          :key="invoice.id"
          class="flex items-center rounded p-4"
        >
          <later-date v-model="invoice.date" class="flex-1" />
          <div class="flex-1">
            <div v-text="invoice.total" />
            <div
              class="text-sm text-gray"
              v-text="capitalize(invoice.status)"
            ></div>
          </div>
          <a
            v-if="invoice.url"
            :href="invoice.url"
            class="mdc-button"
            target="_blank"
            >View</a
          >
          <mdc-button
            v-else
            class="flex-1"
            ripple
            @click.native="download(invoice)"
            v-text="'Download'"
          />
        </div>
      </div>
    </div>

    <mdc-dialog
      v-if="showCardInput"
      :title="choice ? `${randomWord()} choice!` : 'Update Card'"
      type="form"
      classes="w-96"
      @submit="submitCardInfo"
      @closing="closeCardInput"
    >
      <div v-if="choice" class="text-xs leading-normal">
        You have chosen the {{ capitalize(choice.plan.name) }} plan, billed
        {{ choice.yearly ? 'yearly' : 'monthly' }}. You'll be charged ${{
          choice.yearly ? choice.plan.yearly.rate : choice.plan.monthly.rate
        }}.00 today. You may choose to turn off automatic billing after hitting
        submit. Payments are processed by Stripe. Contact us if you need an
        alternative payment method to a credit card.
      </div>
      <div
        id="card-element"
        class="bg-success-light rounded shadow-lg p-4 my-8"
      />
    </mdc-dialog>

    <mdc-dialog
      v-if="showEmailInput"
      title="Update Billing Email Address"
      type="form"
      classes="w-96"
      @submit="submitEmailInput"
      @closing="showEmailInput = false"
    >
      <input label="Email" />
    </mdc-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import axios from '@/utils/xhr';
import { alertDialog, confirmDialog } from '@/utils/dialog';
import { capitalize } from '@/utils/string';
import LaterDate from '@/components/date.vue';
import LaterPlans from '@/components/plans.vue';
import MdcButton from '@/components/mdc/button.vue';
import MdcDialog from '@/components/mdc/dialog.vue';

export default {
  components: {
    LaterDate,
    LaterPlans,
    MdcButton,
    MdcDialog,
  },

  data: () => ({
    loaded: false,
    stripe: null,
    cardElement: null,
    invoices: [],
    card: null,
    cardError: 'Your credit card info is incomplete.',
    choice: null,
    customer: null,
    showPlans: false,
    showCardInput: false,
    showEmailInput: false,
    submitting: false,
  }),

  computed: {
    listedInvoices() {
      return this.invoices.filter((invoice) => invoice.status !== 'void');
    },

    ...mapState({
      plan: (state) => state.user.plan,
    }),
  },

  mounted() {
    this.fetch();
    this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY);
  },

  methods: {
    capitalize,

    fetch() {
      axios.post(`${this.$route.path}/results`).then((response) => {
        this.invoices = response.data.invoices;
        this.card = response.data.card;
        this.customer = response.data.customer;
        this.showPlans = false;
        this.loaded = true;
      });
    },

    openPlans() {
      this.showPlans = !this.showPlans;
      this.$nextTick(() => {
        document.body.scrollTop = 0;
      });
    },

    choosePlan(choice) {
      if (this.customer && !this.card) {
        alertDialog('Please add a payment method first.');
        return;
      }

      this.choice = choice;

      if (!this.card) this.openCardInput();
      else {
        axios
          .post(`${this.$route.path}/swap`, {
            later_plan: this.choice.plan.name,
            later_interval: this.choice.yearly ? 'yearly' : 'monthly',
          })
          .then((r) => {
            this.$store.commit('user', r.data);
            this.closeCardInput();
            this.fetch();
          });
      }
    },

    openCardInput() {
      this.showCardInput = true;
      this.$nextTick(() => {
        this.cardElement = this.stripe.elements().create('card');
        this.cardElement.on('change', (event) => {
          if (event.complete) this.cardError = false;
          else if (event.error) this.cardError = event.error.message;
          else this.cardError = 'Your credit card info is incomplete.';
        });
        this.cardElement.mount('#card-element');
      });
    },

    closeCardInput() {
      this.showCardInput = false;
      this.choice = null;
    },

    submitCardInfo() {
      if (this.cardError) {
        alertDialog(this.cardError);
        return;
      }

      if (this.submitting) return;
      this.submitting = true;

      axios
        .post(`${this.$route.path}/intention`)
        .then(async (response) => {
          const { setupIntent, error } = await this.stripe.confirmCardSetup(
            response.data.client_secret,
            {
              payment_method: { card: this.cardElement },
            }
          );
          if (error) {
            this.submitting = false;
            alertDialog(error.message);
          } else {
            const params = {
              payment_method: setupIntent.payment_method,
            };
            if (this.choice) {
              params.later_plan = this.choice.plan.name;
              params.later_interval = this.choice.yearly ? 'yearly' : 'monthly';
            }
            axios
              .post(
                `${this.$route.path}/${this.choice ? 'create' : 'card'}`,
                params
              )
              .then((r2) => {
                this.$store.commit('user', r2.data);
                this.closeCardInput();
                this.fetch();
                this.submitting = false;
              });
          }
        })
        .catch(() => {
          this.submitting = false;
        });
    },

    submitEmailInput() {},

    randomWord() {
      const words = [
        'superb',
        'outstanding',
        'magnificent',
        'exceptional',
        'marvelous',
        'wonderful',
        'sublime',
        'perfect',
        'supreme',
        'first-rate',
        'first-class',
        'superior',
        'superlative',
        'splendid',
        'admirable',
        'worthy',
        'sterling',
        'fine',
        'A+',
        'great',
        'terrific',
        'tremendous',
        'fantastic',
        'fab',
        'top-notch',
        'awesome',
        'magic',
        'wicked',
        'cool',
        'brill',
        'smashing',
        'champion',
      ];
      return capitalize(
        words[Math.floor(Math.random() * Math.floor(words.length))]
      );
    },

    deleteCard() {
      confirmDialog('Are you sure you want to delete your card?')
        .then(() => {
          axios.delete(`${this.$route.path}/card`).then((response) => {
            this.$store.commit('user', response.data);
            this.fetch();
          });
        })
        .catch(() => {});
    },

    download(invoice) {
      axios({
        method: 'GET',
        url: `${this.$route.path}/invoices/${invoice.id}`,
        responseType: 'blob',
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `invoice-later-io-${invoice.date.substring(0, 10)}.pdf`
        );
        link.click();
        window.URL.revokeObjectURL(url);
      });
    },

    cancelSubscription() {
      confirmDialog(
        'Are you sure you want to cancel your subscription?',
        'This means your plan will expire at the end of this billing cycle.'
      )
        .then(() => {
          axios.post(`${this.$route.path}/cancel`).then((response) => {
            this.$store.commit('user', response.data);
            this.fetch();
          });
        })
        .catch(() => {});
    },

    resumeSubscription() {
      axios.post(`${this.$route.path}/resume`).then((response) => {
        this.$store.commit('user', response.data);
        this.fetch();
      });
    },
  },
};
</script>
